<template>

  <!-- Table Container Card -->
  <b-card
    no-body
  >
    <div class="m-2">

      <!-- Table Top -->
      <b-row>

        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <label>{{ $t('ENTRIES') }}</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block ml-50 mr-1"
          />
          <b-button
            variant="primary"
            :to="{ name: 'media-configuration'}"
          >
            <span> {{ $t('ADD_MEDIA') }} </span>
            <feather-icon icon="PlusCircleIcon" />
          </b-button>
        </b-col>
        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              :placeholder="$t('SEARCH')"
            />
          </div>
        </b-col>
      </b-row>

    </div>
    <b-table
      ref="refMediaListTable"
      :items="allMedias"
      responsive
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      :empty-text="$t('NO_MATCHING_RECORDS_FOUND')"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <!-- Column: mediaID -->
      <template #cell(ID)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.mediaID }}
          </span>
        </b-media>
      </template>
      <!-- Column:Header -->
      <template #cell(Header)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.header }}
          </span>
        </b-media>
      </template>
      <!-- Column:Url -->
      <template #cell(Url)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ strSubstring(data.item.newmediapath,20) }}
          </span>
        </b-media>
      </template>
      <!-- Column:Scope -->
      <template #cell(scope)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ data.item.scope === 1 ? 'PRIVATE' : 'PUBLIC' }}
          </span>
        </b-media>
      </template>
      <!-- Column:Language -->
      <template #cell(Language)="data">
        <b-media vertical-align="center">
          <span class="font-weight-bold d-block text-nowrap">
            {{ resolveLanguage(data.item.lang) }}
          </span>
        </b-media>
      </template>

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <feather-icon
            :id="`game-row-${data.item.mediaID}-send-icon`"
            icon="EditIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="$router.push({ name: 'media-configuration-id', params: { id: data.item.mediaID }})"
          />
          <feather-icon
            :id="`game-row-${data.item.ID}-send-icon`"
            icon="VideoIcon"
            class="cursor-pointer mr-1"
            size="16"
            @click="openMediaModel(data.item)"
          />
          <feather-icon
            :id="`game-row-${data.item.mediaID}-preview-icon`"
            icon="MessageSquareIcon"
            size="16"
            class="cursor-pointer mr-1"
            @click="navigateQuizConfiguration(data.item)"
          />
          <feather-icon
            :id="`game-row-${data.item.mediaID}-preview-icon`"
            icon="Trash2Icon"
            size="16"
            class="cursor-pointer"
            @click="removeMedias(data.item)"
          />
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>

        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-start"
        >
          <span class="text-muted">{{ $t('SHOWING') }} {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} {{ $t('ENTRIES') }}</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="d-flex align-items-center justify-content-center justify-content-sm-end"
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalMedias"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
    <media-model
      :view-media="viewMedia"
    />
  </b-card>
</template>

<script>
import {
  BCard,
  BRow, BCol, BFormInput, BButton, BTable,
  BMedia,
  BPagination, VBPopover,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from 'vuex'
import { ref, computed } from '@vue/composition-api'
import { mixinList } from '@/constants/mixinValidations'
// eslint-disable-next-line import/extensions, import/no-unresolved
import { mixinDate } from '@/constants/mixinDate'
import constants from '@/constants/static.json'
// eslint-disable-next-line import/extensions, import/no-unresolved
import mixinAlert from '@/constants/mixinAlert'
import MediaModel from './MediaModel.vue'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BPagination,
    vSelect,
    // eslint-disable-next-line vue/no-unused-components
    VBPopover,
    MediaModel,
  },
  directives: {
    'b-popover': VBPopover,
    Ripple,
  },
  mixins: [mixinList, mixinDate],
  data() {
    return {
      viewMedia: {
        mediaID: 0,
        url: '',
        newmediapath: '',
        companyID: 0,
        header: '',
        description: '',
        lang: '',
        scope: '',
      },
    }
  },
  setup() {
    const tableColumns = [
      { key: 'ID', sortable: false },
      { key: 'Header', sortable: false },
      { key: 'Url', sortable: false },
      { key: 'Scope', sortable: false },
      { key: 'Language', sortable: false },
      { key: 'Actions' },
    ]

    const { PER_PAGE_OPTIONS, PER_PAGE } = constants
    const refMediaListTable = ref(null)

    const perPage = ref(PER_PAGE)
    const totalMedias = ref(0)
    const currentPage = ref(1)
    const perPageOptions = PER_PAGE_OPTIONS
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)
    const statusFilter = ref('')

    const dataMeta = computed(() => {
      const localItemsCount = refMediaListTable.value ? refMediaListTable.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalMedias.value,
      }
    })

    const refetchData = () => {
      refMediaListTable.value.refresh()
    }

    const {
      successMessage, errorMessage, showErrorMessage,
    } = mixinAlert()

    return {
      tableColumns,
      perPage,
      currentPage,
      totalMedias,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMediaListTable,
      statusFilter,
      refetchData,
      successMessage,
      errorMessage,
      showErrorMessage,
    }
  },
  created() {
    this.fetchMedias()
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    ...mapState('media', ['allMedias', 'mediaCount']),
  },
  // eslint-disable-next-line vue/order-in-components
  watch: {
    currentPage: {
      handler() {
        this.fetchMedias()
      },
    },
  },
  methods: {
    ...mapActions('media', ['fetchAllMedias', 'deleteMedia']),
    removeMedias(data) {
      this.$bvModal
        .msgBoxConfirm(`${this.$i18n.t('MESSAGE.DELETED_CONFIRMATION')} ${data.header}  ${this.$i18n.t('Media')} ?`, {
          title: 'Please Confirm',
          size: 'sm',
          okVariant: 'primary',
          okTitle: 'Yes',
          cancelTitle: 'No',
          cancelVariant: 'outline-secondary',
          hideHeaderClose: false,
          centered: true,
        })
        .then(value => {
          if (value) {
            this.onDeleteMedias(data)
          }
        })
    },
    onDeleteMedias(data) {
      if (data) {
        this.deleteMedia(data).then(response => {
          if (response) {
            this.successMessage(this.$i18n.t('MESSAGE.MEDIA_DELETED'))
            this.fetchMedias()
          }
        }).catch(() => {
          this.showErrorMessage()
        })
      }
    },
    resolveLanguage(data) {
      const language = constants.LANGUAGES.find(i => i.country_code === data)
      if (language) {
        return language.name
      }
      return ''
    },
    fetchMedias() {
      const payload = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      try {
        this.fetchAllMedias(payload).then(() => {
          this.totalMedias = this.mediaCount
        })
      } catch (error) {
        this.errorMessage('There was an issue with fetching the media list')
      }
    },
    openMediaModel(data) {
      if (data) {
        this.viewMedia = { ...data }
        this.$bvModal.show('media-modal')
      }
    },
    navigateQuizConfiguration(data) {
      const id = data.mediaID
      const url = data.newmediapath
      this.$router.push({
        name: 'quiz-configuration-id',
        params: { id },
        query: { url },
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
